import React, { useEffect, useState } from "react";

import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { differenceInBusinessDays, subMonths } from "date-fns";
import { useTranslation } from "react-i18next";

import { MainLayout } from "../../layout/MainLayout";
import { useLoading } from "../../../hooks/useLoading";
import { InputController, SelectController, InputDatePickerController } from "../../../components/CustomInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { CustomAxios } from "../../../custom/axiosCustom";
import { Config } from "../../../utils/configHeader";
import {
  ErrorServer,
  InfoAlert,
  SuccessAlert,
} from "../../../custom/SwalCustom";
import UploadFile from "../../../examples/UploadFile";

const validationFileInitial = {
  required: false,
  type: false,
  size: false,
};

const stateInitial = {
  daysAvailability: 0,
  daysAvailabilityPaid: 0,
  daysAvailabilityPending: 0,
  daysSicknessRecord: 0,
};

const typeDaysAvalibity = [
  "Relax Day",
  "Birthday",
  "Family Day",
  "Personal Day",
];

const statusActive = [
  "Pendiente",
  "Aprobado Gerencia",
  "Aprobado Supervisor"
]

const typeDaysAvalibityPaid = [
  "Permiso NO Remunerado"
];

const daysAllowed = {
  daysPaid: 1,
  daysNotPaid: 10
}

export const RegisterRequestComponent = () => {
  const { t } = useTranslation();
  const { loading, thisLoading, notLoading } = useLoading();
  const schema = yup
    .object()
    .shape({
      supervisorEmail: yup
        .string()
        .email(t("mailValid"))
        .required(t("isRequired")),
      type: yup.string().typeError(t("isRequired")).required(t("isRequired")),
      startDate: yup.date().required(t("isRequired")),
      endDate: yup
        .date()
        .when(
          "startDate",
          (startDate, yup) =>
            startDate &&
            yup.min(
              startDate,
              t("endDateCannotBeforeStartDate")
            )
        )
        .required(t("isRequired")),
    })
    .required();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [metaField, setMetaField] = useState([]);
  const [emailSupervisorList, setEmailSupervisorList] = useState([]);
  const [requestList, setRequestList] = useState(null)
  const [file, setFile] = useState(null);
  const [validateFile, setValidateFile] = useState(validationFileInitial);
  const [dayAvalibity, setDayAvalibity] = useState(stateInitial);
  const [isDaysRequest, setIsDaysRequest] = useState(false);
  const navigate = useNavigate();

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const type = watch("type");

  useEffect(() => {
    if (type === 'Otro') setFile(null)
    validateType();
  }, [endDate, startDate, type]);

  useEffect(() => {
    CustomAxios.get("license-application/all", Config()).then(({ data }) => {
      setRequestList(data)
    }).catch(() => {
      ErrorServer().then()
    })
  }, [])


  const validateRequestPending = async () => {
    if (!requestList) return;
    const matchingRequest = requestList.find(element => element.type === type)
    if (matchingRequest) {
      const currentYear = new Date().getFullYear();
      const matchingRequestYear = new Date(matchingRequest.startDate).getFullYear();
      if (!matchingRequest.approved && !matchingRequest.notApproved && statusActive.includes(matchingRequest.status) && currentYear === matchingRequestYear) {
        await InfoAlert(
          t("invalidRequest"),
          t("alreadyRequest", { type: type, status: matchingRequest.status })
        )
      }
    }
  }


  const validateType = () => {
    let isValid = true;
    let days = differenceInBusinessDays(endDate, startDate) + 1;
    if (typeDaysAvalibity.includes(type)) {
      validateRequestPending()
      if (days > dayAvalibity.daysAvailability) {
        setIsDaysRequest(true);
        InfoAlert(
          t("requestedDays"),
          t("cannotBeGreaterThanVailableDays", { daysAvailability: dayAvalibity.daysAvailability })
        ).then();
        isValid = false;
      } else if (days > daysAllowed.daysPaid) {
        setIsDaysRequest(true);
        InfoAlert(
          t("requestedDays"),
          t("onlyOneDayIsAllowedForApplications", {daysPaid: daysAllowed.daysPaid, type: type})
        ).then();
        isValid = false;
      }

    } else if (type === "Constancia Enfermedad") {
      if (days > dayAvalibity.daysSicknessRecord) {
        setIsDaysRequest(true);
        InfoAlert(
          t("daysForProofIllnessRequested"),
          t("cannotBeGreaterThanVailableDays", { daysAvailability: dayAvalibity.daysSicknessRecord })
        ).then();
        isValid = false;
      }
    } else if (typeDaysAvalibityPaid.includes(type)) {
      if (days > daysAllowed.daysNotPaid) {
        setIsDaysRequest(true);
        InfoAlert(
          t("requestedUnpaidDays"),
          t("cannotBeLongerAllowedDays", {daysNotPaid: daysAllowed.daysNotPaid})
        ).then();
        isValid = false;
      }
    }
    if (isValid) {
      setIsDaysRequest(false);
    }
    return isValid;
  };

  const validateTypeFile = (files) => {
    const allowedFileExtensions = ['pdf', 'docx', 'xlsx', 'jpg', 'png', 'jpeg'];
    const fileName = files.name.toLowerCase();
    if (allowedFileExtensions.some(ext => fileName.endsWith(`.${ext}`))) {
      setValidateFile({
        required: false,
        type: false,
        size: false,
      });

      return true;
    }
    setValidateFile({
      required: true,
      type: true,
      size: false,
    });
    return false;
  };

  const requiredFile = () => {
    setValidateFile({
      type: false,
      size: false,
      required: true,
    });
  };

  const validateSizeFile = (files) => {
    if (files.size < 3000000) {
      setValidateFile({
        required: false,
        type: false,
        size: false,
      });

      return false;
    }
    if (files.size > 3000000) {
      setValidateFile({
        required: false,
        type: false,
        size: true,
      });

      return true;
    }
  };

  const handleChangeFile = (e) => {
    if (e.target.files.length) {
      let isFileType = validateTypeFile(e.target.files[0]);
      if (isFileType) {
        let isFileSize = validateSizeFile(e.target.files[0]);
        if (!isFileSize) {
          setFile(e.target.files[0]);
        }
      }
      return null;
    }
    requiredFile();
  };

  useEffect(() => {
    thisLoading();
    CustomAxios.get("license-application/pickup-fields-metadata", Config())
      .then(({ data: { fields } }) => {
        setMetaField(fields[0].pickListValues);
        notLoading();
      })
      .catch(() => {
        notLoading();
        ErrorServer().then();
      });
  }, []);

  useEffect(() => {
    thisLoading();
    CustomAxios.get("license-application/vacation-days-available", Config())
      .then(({ data }) => {
        setDayAvalibity(data);
        notLoading();
      })
      .catch();
  }, []);

  const validateDay = (days) => {
    if (days < 0) {
      return 0;
    } else {
      return days;
    }
  };

  useEffect(() => {
    thisLoading();
    CustomAxios.get("supervisors", Config())
      .then(({ data }) => {
        setEmailSupervisorList(data);
        notLoading();
      })
      .catch(() => {
        notLoading();
        ErrorServer().then();
      });
  }, []);

  const onRegister = (value) => {
    if (!validateType()) {
      return;
    }

    if (isDaysRequest) {
      return;
    }

    if (file !== null && file.size > 3000000) {
        InfoAlert(
                  t("maximumFileSizeInvoice")
                ).then();
        return;
    }

    thisLoading();
    let formData = new FormData();
    formData.append("file", file === null ? new Blob() : file);
    formData.append("supervisorEmail", value["supervisorEmail"]);
    formData.append("type", value["type"]);
    formData.append(
      "startDate",
      value["startDate"].toISOString().substring(0, 10)
    );
    formData.append("endDate", value["endDate"].toISOString().substring(0, 10));
    formData.append("comments", value["comments"]);
    if (file == null && type !== 'Otro') {
      requiredFile();
      notLoading();
    } else {
      CustomAxios.post("license-application", formData, Config())
        .then(() => {
          notLoading();
          SuccessAlert(t("applicationRegistration"), t("doneSuccessfully", {a: "o"})).then(() =>
            navigate("/solicitudes")
          );
        })
        .catch(({ response: { data } }) => {
          notLoading()
          ErrorAlert(t("errorTitle"), data.message).then()
      })
    }

  };

  const validateProofIllness = () => {
    if (type === "Constancia Enfermedad") {
      return subMonths(new Date(), 1);
    }
  
      return new Date();
  };

  return (
    <MainLayout loading={loading}>
      <Card>
        <MDBox p={3}>
          <Typography variant="h5">{t("registerApplication")}</Typography>
          <form onSubmit={handleSubmit(onRegister)}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <SelectController
                    control={control}
                    defaultValue={[]}
                    label={t("type")}
                    name="type"
                    listValue={metaField}
                  />
                  {errors.type && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.type.message}
                    </span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <Controller
                    name="supervisorEmail"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: true,
                      maxLength: 50,
                    }}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("supervisorEmail")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Age"
                          {...field}
                        >
                          {emailSupervisorList.map((emailSupervisor, index) => (
                            <MenuItem key={index} value={emailSupervisor.email}>
                              {emailSupervisor.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.supervisorEmail && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.supervisorEmail.message}
                    </span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputDatePickerController
                    control={control}
                    disabledWeekEnds
                    defaultValue={validateProofIllness()}
                    minDate={validateProofIllness()}
                    openTo="month"
                    name="startDate"
                    label={t("startDate")}
                    views={["year", "month", "day"]}
                    readOnlyInput={true}
                  />
                  {errors.startDate && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.startDate.message}
                    </span>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputDatePickerController
                    control={control}
                    disabledWeekEnds
                    defaultValue={validateProofIllness()}
                    minDate={validateProofIllness()}
                    openTo="month"
                    name="endDate"
                    label={t("endDate")}
                    views={["year", "month", "day"]}
                    readOnlyInput={true}
                  />
                  {errors.endDate && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.endDate.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputController
                    name="comments"
                    control={control}
                    type="text"
                    defaultValue=""
                    label={t("observations")}
                  />
                  {errors.firstName && (
                    <span style={{ fontSize: 12, color: "red" }}>
                      {errors.comments.message}
                    </span>
                  )}
                </FormControl>
              </Grid>
              {
                type !== 'Otro' && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                      <UploadFile
                        id="coverPage"
                        nameInput="cover"
                        onChangeFile={handleChangeFile}
                        file={file}
                        textButton={t("uploadEvidence")}
                        fileLoaded={t("uploadedEvidence")}
                      />
                      {validateFile.size && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {t("maximumFileSizeInvoice")}
                        </span>
                      )}
                      {validateFile.type && (
                        <span style={{ fontSize: 12, color: "red" }}>
                          {t("applicationFileValid")}
                        </span>
                      )}
                      {validateFile.required && (
                        <span style={{ fontSize: 12, color: "red" }}>{t("attachApproval")}</span>)}
                    </FormControl>
                  </Grid>
                )
              }
              <Grid item xs={12}>
                <Typography variant="h6">
                  {t("daysAvailability")}: {validateDay(dayAvalibity.daysAvailability)}
                </Typography>
                <Typography variant="h6">
                  {t("daysSicknessRecord")}:{" "}
                  {validateDay(dayAvalibity.daysSicknessRecord)}
                </Typography>
                <Typography variant="h6">
                  {t("daysAvailabilityPaid")}:{" "}
                  {validateDay(dayAvalibity.daysAvailabilityPaid)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  variant="gradient"
                  type="submit"
                  color="info"
                  fullWidth
                >
                  {t("register")}
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </MDBox>
      </Card>
    </MainLayout>
  );
};
